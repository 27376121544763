<template lang="pug">
include ../../../configs/template
div.row
  div.col-12
    +select('status_document', 'statusChoose("StudentID")', 'status', 'labelName')
  div.col-12.justify-center
    v-btn(@click="saveNewStatus" color="success" :loading="isLoader") {{ $t('save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'
export default {
  name: 'SailorStudentEditStatus',
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      isLoader: false,
      status_document: this.sailorDocument.status_document
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['statusChoose'])
  },
  methods: {
    ...mapActions(['updateCadetCard']),
    async saveNewStatus () {
      this.isLoader = true
      let data = {
        ...this.$route.params,
        body: {
          status_document: this.status_document
        }
      }
      const response = await this.updateCadetCard(data)
      if (SUCCESS_CODE.includes(response.code)) {
        !this.$route.params?.documentID && (this.$parent.isViewAddSlot = false)
      }
      this.isLoader = false
    }
  }
}
</script>
